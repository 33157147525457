import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from 'react';
import abiData from './abi.js';
import { ethers } from 'ethers';
import './App.css';

function App() {
  const [show, setShow] = useState(false);
  const [isMonitoringStarted, setIsMonitoringStarted] = useState(false);
  const [deadWalletBalance, setDeadWalletBalance] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function _checkMonitoringStarted(contract) {
    try {
       const isMonitoringStarted = await contract.monitoringStarted();
       setIsMonitoringStarted(isMonitoringStarted);
    } catch (error) {
       console.error('Error calling monitoringStarted:', error);
       setIsMonitoringStarted(false);
    }
  }

  async function _getBalance(contract) {
    try {
       const balance = await contract.balanceOf('0x000000000000000000000000000000000000dEaD');
       const balanceInEther = ethers.formatEther(balance);
       const formattedBalance = Number(balanceInEther).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
       });
       setDeadWalletBalance(formattedBalance.toString());
    } catch (error) {
       console.error('Error calling balance:', error);
    }
  }

  useEffect(() => {
    const checkMonitoringStarted = async () => {
      const contractAddress = '0x8cA77b28ff4a84F06F58503bDBCC36715eD7953E';
      const provider = new ethers.WebSocketProvider(process.env.REACT_APP_WEBSOCKET_URL);
      const contract = new ethers.Contract(contractAddress, abiData, provider);
  
      await _checkMonitoringStarted(contract);
      await _getBalance(contract);
    };
    checkMonitoringStarted().then(() => {
      console.log('is monitoring started: ', isMonitoringStarted);
    }).catch((error) => {
      console.error(error);
    });
  }, []);
  return (
    <>
    <a type="button" onClick={handleShow} id="statistics-button" class="default-btn">Statistics</a>

    <Modal show={show} onHide={handleClose} size='lg' centered>
      <Modal.Header closeButton id='statistics_modal_header'>
        <Modal.Title>BuyBack Statistics</Modal.Title>
      </Modal.Header>
      <Modal.Body id='statistics_modal_body'>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              Price Monitoring Is {isMonitoringStarted ? 'On' : 'Off'}
            </Col>
          </Row>

          <Row style={{marginTop: '20px'}}>
            <Col xs={12} md={12}>
              Total Burn
            </Col>
            <Col xs={12} md={12}>
             {deadWalletBalance} $SMARTMEME
            </Col>
            <Col xs={12} md={12} style={{marginTop: '20px'}}>
             Check From <a style={{color: '#692fbf'}} href="https://bscscan.com/token/0x8cA77b28ff4a84F06F58503bDBCC36715eD7953E?a=0x000000000000000000000000000000000000dead" target="_blank">HERE</a>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer id='statistics_modal_footer'>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
}

export default App;
